import { useFeatureFlag } from "common/feature_gating";
import {
  COMMAND_CENTER_ACCESS_CONTROLS,
  IA_NAV,
  MOVE_TOOLS,
  ORGANIZATION_BRAND_NAME,
  PROOF_TRANSACTIONS,
  SENDER_ROLE_PHASE_3,
  USER_DASHBOARD_RESKIN,
  TXN_DETAILS_REDESIGN_BIZ,
  TXN_DETAILS_REDESIGN_LENDER,
  TXN_DETAILS_REDESIGN_TITLE,
  TXN_DETAILS_REDESIGN_KEYSTONE,
  SIGN_TRANSACTIONS,
} from "constants/feature_gates";
import { usePermissions } from "common/core/current_user_role";
import { isNotaryNST } from "common/notary/capacity";
import { includesOrgAdminRole } from "util/organization";
import { useViewer } from "util/viewer_wrapper";
import AppSubdomains, { CURRENT_PORTAL, type Subdomain } from "constants/app_subdomains";
import Apps from "constants/applications";
// eslint-disable-next-line import/no-restricted-paths
import type { LenderRouterViewer_viewer as LenderViewer } from "lender_portal/router/viewer_query.graphql";
// eslint-disable-next-line import/no-restricted-paths
import type { TitleRouterViewer_viewer as TitleViewer } from "title_portal/router/viewer_query.graphql";
// eslint-disable-next-line import/no-restricted-paths
import type { BusinessRouterViewer_viewer as BusinessViewer } from "business_portal/router/viewer_query.graphql";
import type { TransactionDetailsOrganization } from "common/details/identity";

export function useProofDefendUpsell(
  organization?: Pick<TransactionDetailsOrganization, "proofDefend"> | null,
): boolean {
  const { viewer } = useViewer();
  const userRoles = viewer.user?.organizationMembership?.roles;
  return (
    useFeatureFlag("proof-defend-upsell") &&
    Boolean(organization && !organization.proofDefend) &&
    includesOrgAdminRole(userRoles ?? [])
  );
}

export function useProofDefend(
  organization?: Pick<TransactionDetailsOrganization, "proofDefend"> | null,
): boolean {
  return Boolean(organization?.proofDefend);
}

// Remove in REAL-8187
export function useIAnav(): boolean {
  return useFeatureFlag(IA_NAV);
}

export function useSignTransactionsEnabled(): boolean {
  return useFeatureFlag(SIGN_TRANSACTIONS);
}

export function useProofTransactionsEnabled(
  notaryProfile: Parameters<typeof isNotaryNST>[0],
): boolean {
  return useFeatureFlag(PROOF_TRANSACTIONS) && !isNotaryNST(notaryProfile);
}

export function useOrganizationBrandName(): boolean {
  return useFeatureFlag(ORGANIZATION_BRAND_NAME);
}

export function useSenderRolePhase3(): boolean {
  return useFeatureFlag(SENDER_ROLE_PHASE_3);
}

export function useUserDashboardReskin(): boolean {
  return useFeatureFlag(USER_DASHBOARD_RESKIN);
}

export function useOdnRemoteWitnessCredViewer(): boolean {
  return useFeatureFlag("new-credential-viewer-for-odn-witness-enabled");
}

export function useOnboardingV2(): boolean {
  return useFeatureFlag("onboarding-v2");
}

export function useCommandCenterAccessControls(): boolean {
  return useFeatureFlag(COMMAND_CENTER_ACCESS_CONTROLS);
}

export function useDeepfakeAnalysisFeature(): boolean {
  const flagEnabled = useFeatureFlag("deepfake-analysis");
  const { hasPermissionFor } = usePermissions();

  return flagEnabled || hasPermissionFor("viewDeepfakeAnalysis");
}

export function useDeepfakeAnalysisPermission(): boolean {
  const { viewer } = useViewer<TitleViewer | LenderViewer | BusinessViewer>();
  const { hasPermissionFor } = usePermissions();

  return (
    Boolean(viewer.user?.organization?.proofDefend) || hasPermissionFor("viewDeepfakeAnalysis")
  );
}

export function useTxnDetailsRedesign(portal: Subdomain = AppSubdomains[CURRENT_PORTAL]): boolean {
  const getFlagName = () => {
    switch (portal) {
      case "business":
        return TXN_DETAILS_REDESIGN_BIZ;
      case "lender":
        return TXN_DETAILS_REDESIGN_LENDER;
      case "title":
        return TXN_DETAILS_REDESIGN_TITLE;
      case "keystone":
        return TXN_DETAILS_REDESIGN_KEYSTONE;
      default:
        return TXN_DETAILS_REDESIGN_BIZ;
    }
  };

  return useFeatureFlag(getFlagName());
}

export function usePeerReferrals(): boolean {
  return useFeatureFlag("peer-referrals");
}

export function useProMobileOnboarding(): boolean {
  return useFeatureFlag("pro-mobile-onboarding");
}

export function useMoveTools() {
  const moveTools = useFeatureFlag(MOVE_TOOLS);
  if (CURRENT_PORTAL === Apps.BUSINESS) {
    return moveTools;
  }
  return false;
}

export function useShowIdentityInfo(organization?: TransactionDetailsOrganization | null): boolean {
  return Boolean(organization?.showIdentityInformation);
}

export function isFeatureEnabled(
  organization: { featureFlags: { key: string; value: string }[] },
  flag: string,
): boolean {
  return organization.featureFlags.find((f) => f.key === flag)?.value === "true";
}
