import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import { IconButton } from "common/core/button/icon_button";
import { format } from "common/core/format/date";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import { Heading, Paragraph, Substyle } from "common/core/typography";
import { userFullName } from "util/user";
import IdentityDocumentViewer, {
  parsePhotosForDocumentViewer,
} from "common/identity/document_viewer";
import IdentityTimeline from "common/identity/timeline";
import { getPhotoRequirementsForDocumentViewer } from "common/identity/document_viewer/engine";
import { Badge } from "common/core/badge";
import { FraudStatusEnum, RiskLevels } from "graphql_globals";
import { useProofDefend, useProofDefendUpsell, useShowIdentityInfo } from "util/feature_detection";
import RiskLevel from "common/dashboard/risk_level";
import { EmptyState } from "common/details/identity/common/empty_state";
import { ProofDefendUpsellEmptyState } from "common/details/identity/marketing/defend";

import Styles from "../index.module.scss";
import {
  type IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners as TransactionCustomer,
  type IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_signerIdentities as TransactionCustomerIdentity,
  type IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_proofRequirement as ProofRequirementInputType,
} from "../index.query.graphql";
import type { TransactionDetailsOrganization } from "..";
import EmailRiskSection from "./email_risk";
import type { useRiskModalState } from "../modals/risk";
import SignerAttributes from "./attributes";
import SignerProfile from "./profile";
import IpAddressSection from "./ip_address_section";
import PhoneRiskSection from "./phone_risk";
import {
  BusinessPremiumUpsellBanner,
  BusinessPremiumUpsellEmptyState,
} from "../marketing/business_premium";

type OpenRiskModalCallback = ReturnType<typeof useRiskModalState>["openModal"];

type SignerProps = {
  customer: TransactionCustomer;
  organization: TransactionDetailsOrganization;
  markAsFraud?: () => void;
  updatingFraudStatus: boolean;
  openRiskModal: OpenRiskModalCallback;
  collapsible: boolean;
  index: number;
};

const Messages = defineMessages({
  expand: {
    id: "305038c8-df4e-4255-9a52-b74fadf6e341",
    defaultMessage: "Expand content",
  },
  collapse: {
    id: "49a6dc64-ba50-46aa-a168-ebccb7fd1e24",
    defaultMessage: "Collapse content",
  },
});

function CollapseToggle({ onClick, expanded }: { onClick: () => void; expanded: boolean }) {
  const intl = useIntl();
  return (
    <IconButton
      iconClassName={Styles.dropdownIcon}
      buttonColor="dark"
      variant="tertiary"
      buttonSize="condensed"
      onClick={onClick}
      aria-expanded={expanded}
      label={intl.formatMessage(expanded ? Messages.collapse : Messages.expand)}
      name={expanded ? "caret-up" : "caret-down"}
    />
  );
}

export function Signer({
  customer,
  organization,
  markAsFraud,
  updatingFraudStatus,
  openRiskModal,
  collapsible,
  index,
}: SignerProps) {
  const signerIdentities = customer.signerIdentities ?? [];
  const [expanded, setExpanded] = useState(true);
  const proofDefend = useProofDefend(organization);
  const showIdentityInformation = useShowIdentityInfo(organization);
  const signerMarkedAsFraud = customer.fraudStatus?.status === FraudStatusEnum.FRAUD;

  return (
    <div className={Styles.signer}>
      <div
        className={classnames(Styles.header, {
          [Styles.headerCollapsible]: collapsible,
        })}
        onClick={() => {
          if (collapsible) {
            setExpanded(!expanded);
          }
        }}
      >
        <div>
          {collapsible && (
            <CollapseToggle expanded={expanded} onClick={() => setExpanded(!expanded)} />
          )}
          <Heading textStyle="headingFour" level="h2">
            {userFullName(customer)}
          </Heading>
          <Paragraph textColor="subtle">
            <FormattedMessage
              id="eb8f554d-651c-4da3-9325-4113a026a87b"
              defaultMessage="(Identity {signerIndex})"
              values={{ signerIndex: index + 1 }}
            />
          </Paragraph>
          {signerMarkedAsFraud && (
            <Badge kind="warning">
              <FormattedMessage
                id="5dbae2c5-2798-41bb-a780-68723d23c8b0"
                defaultMessage="Identity flagged"
              />
            </Badge>
          )}
        </div>
        <div>
          {Boolean(markAsFraud) && (
            <Button
              buttonColor="action"
              variant="secondary"
              onClick={markAsFraud}
              withIcon={{ name: "state", placement: "left" }}
              isLoading={updatingFraudStatus}
              data-automation-id={`${signerMarkedAsFraud ? "unflag" : "flag"}-identity-button`}
            >
              {signerMarkedAsFraud ? (
                <FormattedMessage
                  id="a3b6d5e7-cb95-47e2-bcee-cfe0d0934c93"
                  defaultMessage="Unflag identity"
                />
              ) : (
                <FormattedMessage
                  id="c197cf38-c2d2-47e0-965f-7f6006eb7dc1"
                  defaultMessage="Flag identity"
                />
              )}
            </Button>
          )}
        </div>
      </div>
      <section>
        <SignerProfile customer={customer} />
      </section>
      {showIdentityInformation ? (
        <>
          {proofDefend && (
            <section>
              <EmailRiskSection customer={customer} />
            </section>
          )}
          {proofDefend && (
            <section>
              <PhoneRiskSection customer={customer} />
            </section>
          )}
          {expanded &&
            (signerIdentities.length > 0 ? (
              signerIdentities.map((si, i) => (
                <SignerAttempt
                  key={si.id}
                  signerIdentity={si}
                  organization={organization}
                  attempt={signerIdentities.length - i}
                  proofRequirement={customer.proofRequirement}
                  openRiskModal={openRiskModal}
                  initiallyExpanded={i === 0}
                  canShowDefendUpsell={i === 0}
                />
              ))
            ) : (
              <section>
                <EmptyState className={Styles.emptySignerIdentityState} />
              </section>
            ))}
        </>
      ) : (
        expanded && (
          <>
            <section className={Styles.upsellSection}>
              <BusinessPremiumUpsellBanner />
            </section>
            <section className={Styles.upsellSection}>
              <BusinessPremiumUpsellEmptyState />
            </section>
          </>
        )
      )}
    </div>
  );
}

function SignerAttempt({
  attempt,
  organization,
  signerIdentity,
  proofRequirement,
  openRiskModal,
  initiallyExpanded = false,
  canShowDefendUpsell = false,
}: {
  attempt: number;
  organization: TransactionDetailsOrganization;
  signerIdentity: TransactionCustomerIdentity;
  proofRequirement: ProofRequirementInputType | null;
  openRiskModal: OpenRiskModalCallback;
  initiallyExpanded?: boolean;
  canShowDefendUpsell?: boolean;
}) {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const proofDefendUpsell = useProofDefendUpsell(organization);
  const proofDefend = useProofDefend(organization);
  const homeAddress = signerIdentity.identityAttributes?.find(
    (attr) => attr.__typename === "IdentityAttributeHomeAddress",
  );
  const aggregateRiskLevel = signerIdentity.aggregateRiskLevel;
  const canViewSignals = proofDefend && aggregateRiskLevel;

  return (
    <section className={Styles.attemptSection}>
      <div className={Styles.attemptHeader}>
        <div>
          <Heading textStyle="subtitle" level="h3">
            <FormattedMessage
              id="fd8bdba1-650f-4be7-9c5f-53a2b5ebd08f"
              defaultMessage="Verification attempt {attempt}"
              values={{ attempt }}
            />
          </Heading>
          {canViewSignals && [RiskLevels.HIGH, RiskLevels.MEDIUM].includes(aggregateRiskLevel) && (
            <RiskLevel level={aggregateRiskLevel} textLength="medium" />
          )}
          <Substyle textColor="subtle" size="small">
            {format({ value: signerIdentity.createdAt, formatStyle: "EEE LLL dd, h:mm aa" })}
          </Substyle>
        </div>
        <div>
          {canViewSignals && Boolean(signerIdentity.riskSignals?.length) && (
            <LinkStyledButton
              onClick={() => openRiskModal(signerIdentity.customerId, signerIdentity.id)}
              underlined={false}
            >
              <FormattedMessage
                id="e348fb86-b99c-491c-9d2c-e5763cb76d5c"
                defaultMessage="View signals"
              />
            </LinkStyledButton>
          )}
          <CollapseToggle expanded={expanded} onClick={() => setExpanded(!expanded)} />
        </div>
      </div>

      {expanded && (
        <>
          <section>
            <SignerAttributes signerIdentity={signerIdentity} proofRequirement={proofRequirement} />
          </section>
          <section>
            <IdentityTimeline
              identityTimeline={signerIdentity.identityTimeline}
              options={{ horizontal: true }}
            />
          </section>
          {proofDefendUpsell && canShowDefendUpsell && (
            <section>
              <ProofDefendUpsellEmptyState />
            </section>
          )}
          {proofDefend && signerIdentity.ipAddressLogs && (
            <section>
              <IpAddressSection
                ips={signerIdentity.ipAddressLogs}
                homeAddress={homeAddress?.homeAddressValue}
              />
            </section>
          )}
          {signerIdentity.photoId && (
            <section>
              <IdentityDocumentViewer
                photos={parsePhotosForDocumentViewer(signerIdentity)}
                requirements={getPhotoRequirementsForDocumentViewer(signerIdentity)}
                className={Styles.documentViewer}
              />
            </section>
          )}
        </>
      )}
    </section>
  );
}
