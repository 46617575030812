import type { ComponentProps, ReactNode } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";

import { Heading, Paragraph } from "common/core/typography";
import Icon from "common/core/icon";
import { Hr } from "common/core/horizontal_rule";
import {
  AutomaticFormRow,
  AutomaticFormRowLayout,
  makeAutomaticFormRowElementProps,
} from "common/core/form/layout";
import { BinaryToggle } from "common/core/form/binary_toggle";
import {
  Controller,
  type FieldError,
  type FieldPath,
  type FieldPathByValue,
  type FieldValues,
  type UseFormReturn,
  type RegisterOptions,
} from "common/core/form";
import { useMobileScreenClass } from "common/core/responsive";
import { useId } from "util/html";
import {
  emailPatternValidation,
  FormattedFieldError,
  defaultRequiredMessage,
  isAriaInvalid,
  useNestedError,
  useEmailValidator,
  ErrorMessage,
} from "common/core/form/error";
import {
  ChoiceChip,
  RadioGroup,
  RadioInput,
  RadioLabel,
  CheckboxGroup,
  CheckboxLabel,
  Checkbox,
  type PresentNode,
  type LabelPresent,
  type AriaLabelledByPresent,
} from "common/core/form/option";
import { IconButton } from "common/core/button/icon_button";
import PopoutMenuItem from "common/core/popout_menu/item";
import PopoutMenu from "common/core/popout_menu";
import AlertMessage from "common/core/alert_message";
import { segmentTrackAsync } from "util/segment";
import { useActiveOrganization } from "common/account/active_organization";
import { DeprecatedDatePicker } from "common/form/inputs/datepicker";
import { DeprecatedStyledSelectInput } from "common/form/inputs/select";

import Styles from "./index.module.scss";
import type { Config, ConfiguredField as TConfiguredField } from "../config";
import type { FormValues, UseForm } from "../form";

export function Page({ children }: { children: ReactNode }) {
  return <div className={Styles.page}>{children}</div>;
}

export function PageHeader({ children, subtitle }: { children: ReactNode; subtitle: ReactNode }) {
  const isMobile = useMobileScreenClass();
  const subtitleId = useId();

  return (
    <header className={Styles.pageHeader}>
      <Heading
        level="h1"
        textStyle={isMobile ? "headingFour" : "headingThree"}
        aria-describedby={subtitle ? subtitleId : undefined}
      >
        {children}
      </Heading>
      {subtitle && (
        <Paragraph id={subtitleId} textColor={isMobile ? undefined : "subtle"}>
          {subtitle}
        </Paragraph>
      )}
      <Hr className={Styles.pageHeaderDivider} />
    </header>
  );
}

export function PageContent({ children }: { children: ReactNode }) {
  return <div className={Styles.pageContent}>{children}</div>;
}

export function PageFooter({
  children,
  errorMessage,
  warningMessage,
}: {
  children: ReactNode;
  errorMessage?: ReactNode;
  warningMessage?: ReactNode;
}) {
  return (
    <footer className={Styles.pageFooter}>
      {warningMessage}
      {errorMessage && (
        <AlertMessage kind="danger" centerText automationId="submit-error-alert">
          {errorMessage}
        </AlertMessage>
      )}
      <div className={Styles.pageFooterButtons}>{children}</div>
    </footer>
  );
}

export function SectionHeader({
  id,
  iconName,
  children,
}: {
  id?: string;
  iconName: ComponentProps<typeof Icon>["name"];
  children: ReactNode;
}) {
  const isMobile = useMobileScreenClass();

  return (
    <div className={Styles.sectionHeader} id={id ?? undefined}>
      <Icon className={Styles.sectionIcon} name={iconName} />
      <Heading level="h2" textStyle={isMobile ? "headingFive" : "headingFour"}>
        {children}
      </Heading>
    </div>
  );
}

export function SubSectionHeader({
  title,
  titleAction,
}: {
  title: ReactNode;
  titleAction?: ReactNode;
}) {
  return (
    <Heading level="h2" textStyle="headingFive" className={Styles.cardSubsectionTitle}>
      {title}
      {titleAction}
    </Heading>
  );
}

export function SectionDivider() {
  return <Hr className={Styles.sectionDivider} />;
}

// this component will be queried as an error and will prevent onSave/onSend actions
// use this in sections that don't render inputs that can apply aria-invalid to
export function SectionErrorMessage({ message }: { message: ReactNode }) {
  return (
    <Paragraph
      size="small"
      textColor="danger"
      className={Styles.sectionErrorMessage}
      role="alert"
      data-section-error
    >
      {message}
    </Paragraph>
  );
}

type ActionMenuItems = {
  selected: boolean;
  onClick: () => void;
  label: string;
  ariaLabel: string;
}[];
type ActionMenuProps = { items: ActionMenuItems; label: string };

function ActionMenu({ items, label }: ActionMenuProps) {
  return (
    <PopoutMenu
      target={
        // @ts-expect-error - PopoutMenu will add onClick via cloneElement
        <IconButton
          name="kebab-menu"
          className={Styles.cardIconButton}
          variant="secondary"
          buttonColor="action"
          buttonSize="condensed"
          label={label}
        />
      }
      placement="bottomRight"
    >
      {({ close }) =>
        items.map((item, i) => (
          <PopoutMenuItem
            key={i}
            iconName={item.selected ? "success" : undefined}
            iconClass={Styles.cardActionMenuItemIcon}
            aria-label={item.ariaLabel}
            onClick={() => {
              close();
              item.onClick();
            }}
          >
            {item.label}
          </PopoutMenuItem>
        ))
      }
    </PopoutMenu>
  );
}

export function Card({
  children,
  title,
  subtitle,
  cardToggleProps,
  removeButtonProps,
  iconName,
  actionMenu,
  colorHex,
  error,
  "data-automation-id": dataAutomationId,
}: {
  children: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  cardToggleProps?: ComponentProps<typeof BinaryToggle>;
  iconName?: string;
  removeButtonProps?: {
    disabled?: boolean;
    onClick: () => void;
    label: string;
    "data-automation-id": string;
  };
  actionMenu?: ActionMenuProps;
  colorHex?: string;
  /** Used to show error at bottom of the card */
  error?: {
    ariaId: string;
    message: ReactNode;
    applyBorder?: boolean;
  };
  "data-automation-id"?: string;
}) {
  const isMobile = useMobileScreenClass();

  const renderTitle = () => {
    return (
      <div className={Styles.cardTitles}>
        <div className={Styles.cardHeadingWrapper}>
          {iconName && <Icon className={Styles.cardIcon} name={iconName} />}
          <Heading level="h3" textStyle={isMobile ? "headingSix" : "headingFive"}>
            {title}
          </Heading>

          {cardToggleProps && (
            <div className={Styles.cardToggle}>
              <BinaryToggle {...cardToggleProps} />
            </div>
          )}
        </div>
        {subtitle && (
          <div className={Styles.cardSubtitleWrapper}>
            <Paragraph textColor="subtle">{subtitle}</Paragraph>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={Styles.card}>
      <div
        className={classnames(
          Styles.cardContent,
          Boolean(error?.applyBorder) && Styles.cardBorderError,
        )}
        data-automation-id={dataAutomationId}
      >
        {colorHex && (
          <div
            className={Styles.cardColorBar}
            style={{
              backgroundColor: colorHex,
            }}
          />
        )}
        <div className={Styles.cardHeader}>
          {renderTitle()}
          <div className={Styles.cardButtons}>
            {removeButtonProps && (
              <IconButton
                className={Styles.cardIconButton}
                disabled={removeButtonProps.disabled}
                name="delete"
                onClick={removeButtonProps.onClick}
                variant="secondary"
                buttonColor="danger"
                buttonSize="condensed"
                label={removeButtonProps.label}
              />
            )}
            {actionMenu && <ActionMenu {...actionMenu} />}
          </div>
        </div>

        {children}
      </div>
      <ErrorMessage id={error?.ariaId} className={Styles.cardError} message={error?.message} />
    </div>
  );
}

export function CardDivider({
  "data-automation-id": dataAutomationId,
}: {
  "data-automation-id"?: string;
}) {
  return <Hr className={Styles.cardDivider} data-automation-id={dataAutomationId} />;
}

type AutomaticFormRowProps<FormValues extends FieldValues> = ComponentProps<
  typeof AutomaticFormRow<FormValues>
>;

type StyledSelectInputProps = ComponentProps<typeof DeprecatedStyledSelectInput>;

function useAddRequiredToRegisterOptions<
  FormValues extends FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>(
  registerOptions: RegisterOptions<FormValues, FieldName> | undefined,
  required: boolean,
  customErrorMsg?: string,
): typeof registerOptions {
  const intl = useIntl();

  if (required) {
    // only override registerOptions.required if not explictly set
    if (!registerOptions || registerOptions.required === undefined) {
      return {
        ...registerOptions,
        required: customErrorMsg ?? defaultRequiredMessage(intl),
      };
    }
  }

  return registerOptions;
}

export function ConfiguredField<FormValues extends FieldValues>({
  config,
  configField,
  requiredOverride,
  disabledOverride,
  ...automaticFormRowProps
}: {
  config: Config;
  configField: TConfiguredField;
  // To be used sparingly, will override requiredField with the provided value (true/false). Provide no value to use
  // requiredField per usual. Should only be used if it absolutely cannot be covered by a config and will be apply
  // across multiple workflows.
  requiredOverride?: boolean;
  // Same as requiredOverride. Meant as a temporary fix until we add config support for array fields and can update
  // individual array fields with modifyConfig.
  disabledOverride?: true;
} & AutomaticFormRowProps<FormValues>) {
  const required = requiredOverride ?? requiredField(config, configField);
  const modifiedRegisterOptions = useAddRequiredToRegisterOptions(
    automaticFormRowProps.registerOptions,
    required,
  );
  const modifiedAutomaticFormRowProps = modifiedRegisterOptions
    ? {
        ...automaticFormRowProps,
        registerOptions: modifiedRegisterOptions,
      }
    : automaticFormRowProps;

  if (!showField(config, configField)) {
    return null;
  }

  return (
    <AutomaticFormRow<FormValues>
      fullWidth
      disabled={disabledOverride ?? readonlyField(config, configField)}
      required={required}
      // we should remove disabled and required from the AutomaticFormRowProps so can't
      // override our custom values here
      {...modifiedAutomaticFormRowProps}
    />
  );
}

export function ConfiguredEmailField<FormValues extends FieldValues>({
  config,
  configField,
  requiredOverride,
  disabledOverride,
  ...automaticFormRowProps
}: ComponentProps<typeof ConfiguredField<FormValues>>) {
  const intl = useIntl();
  const emailValidator = useEmailValidator();
  const baseEmailValidation = { emailValidation: emailValidator };

  let baseRegisterOptions = automaticFormRowProps.registerOptions;

  if (baseRegisterOptions) {
    baseRegisterOptions.validate = {
      ...baseRegisterOptions.validate,
      ...baseEmailValidation,
    };

    baseRegisterOptions.pattern = emailPatternValidation(intl);
  } else {
    baseRegisterOptions = {
      validate: baseEmailValidation,
      pattern: emailPatternValidation(intl),
    };
  }

  return (
    <ConfiguredField
      config={config}
      configField={configField}
      registerOptions={baseRegisterOptions}
      requiredOverride={requiredOverride}
      disabledOverride={disabledOverride}
      {...automaticFormRowProps}
    />
  );
}

type AutomaticFormRowLayoutProps<FormValues extends FieldValues> = ComponentProps<
  typeof AutomaticFormRowLayout<FormValues>
>;
// at some point can just make a more generic ControlledConfiguredField and/or ControlledAutomaticFormRow
export function ConfiguredDateField<
  FormValues extends FieldValues,
  DatePath extends FieldPathByValue<FormValues, Date | null>,
>(props: {
  form: Pick<UseFormReturn<FormValues>, "control" | "trigger">;
  name: DatePath;
  rules?: ComponentProps<typeof Controller<FormValues>>["rules"];
  label?: AutomaticFormRowLayoutProps<FormValues>["label"];
  config: Config;
  configField: TConfiguredField;
  minDate?: Date | null;
  maxDate?: Date | null;
  className?: string;
  "data-automation-id"?: string;
}) {
  const required = requiredField(props.config, props.configField);
  const disabled = readonlyField(props.config, props.configField);
  const modifiedRules = useAddRequiredToRegisterOptions(props.rules, required);
  const error = useNestedError({ control: props.form.control, name: props.name });
  const id = useId();

  if (!showField(props.config, props.configField)) {
    return null;
  }

  const autoProps = makeAutomaticFormRowElementProps({
    id,
    error,
    ariaLabel: undefined,
    ariaLabelledBy: undefined,
    ariaDescribedBy: undefined,
    automationId: props["data-automation-id"],
    placeholder: undefined,
    disabled,
  });

  return (
    <AutomaticFormRowLayout<FormValues>
      id={id}
      disabled={disabled}
      required={required}
      name={props.name}
      error={error}
      className={props.className}
      label={props.label}
      fullWidth
    >
      <Controller
        control={props.form.control}
        name={props.name}
        rules={modifiedRules}
        disabled={disabled}
        render={({ field }) => (
          <DeprecatedDatePicker
            {...autoProps}
            {...field}
            // manually required to trigger form validation as controller onChange fails to call validation
            // root cause associated with controller or date type as form value, TBD - BIZ-6129
            onChange={(value) => {
              field.onChange(value);
              props.form.trigger(props.name);
            }}
            minDate={props.minDate}
            maxDate={props.maxDate}
          />
        )}
      />
    </AutomaticFormRowLayout>
  );
}

export function ConfiguredStyledSelectField<FormValues extends FieldValues>(
  props: {
    form: Pick<UseFormReturn<FormValues>, "control">;
    name: AutomaticFormRowLayoutProps<FormValues>["name"];
    rules?: ComponentProps<typeof Controller<FormValues>>["rules"];
    label?: AutomaticFormRowLayoutProps<FormValues>["label"];
    config: Config;
    configField: TConfiguredField;
    className?: string;
    "data-automation-id"?: string;
    fullWidth?: boolean;
    requiredOverride?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  } & StyledSelectInputProps,
) {
  const required = props.requiredOverride ?? requiredField(props.config, props.configField);
  const disabled = readonlyField(props.config, props.configField);
  const modifiedRules = useAddRequiredToRegisterOptions(props.rules, required);
  const error = useNestedError({ control: props.form.control, name: props.name });
  const id = useId();

  if (!showField(props.config, props.configField)) {
    return null;
  }

  const autoProps = makeAutomaticFormRowElementProps({
    id,
    error,
    ariaLabel: props.ariaLabel,
    ariaLabelledBy: undefined,
    ariaDescribedBy: undefined,
    automationId: props["data-automation-id"],
    placeholder: undefined,
    disabled,
  });

  return (
    <AutomaticFormRowLayout<FormValues>
      id={id}
      disabled={disabled}
      required={required}
      name={props.name}
      error={error}
      className={props.className}
      label={props.label}
      fullWidth={props.fullWidth}
    >
      <Controller
        control={props.form.control}
        name={props.name}
        rules={modifiedRules}
        disabled={disabled}
        render={({ field: { onChange, value } }) => (
          <DeprecatedStyledSelectInput
            {...autoProps}
            value={value}
            onChange={onChange}
            clearable={props.clearable}
            searchable={props.searchable}
            useStyledInput={props.useStyledInput}
            usePlaceholder={props.usePlaceholder}
            placeholderAsLabel={props.placeholderAsLabel}
            closeOnSelect={props.closeOnSelect}
            items={props.items}
            onInputChange={props.onInputChange}
            noResultsText={props.noResultsText}
            onClose={props.onClose}
            optionRenderer={props.optionRenderer}
            valueRenderer={props.optionRenderer}
            onOptionChange={props.onOptionChange}
            filterOptions={props.filterOptions}
          />
        )}
      />
    </AutomaticFormRowLayout>
  );
}

type Option = {
  label: PresentNode;
  value: string;
  disabledOverride?: true;
  "data-automation-id"?: string;
};
type ConfiguredRadioGroupProps =
  | {
      radioButtons: Option[];
      choiceChips?: never;
    }
  | {
      radioButtons?: never;
      choiceChips: Option[];
    };

type ConfiguredRadioGroupLabelProps =
  | { radioGroupLabel: PresentNode; ["aria-labelledby"]?: never }
  | { ["aria-labelledby"]: string; radioGroupLabel?: never };

export function ConfiguredRadioGroup({
  config,
  configField,
  form,
  name,
  radioButtons,
  choiceChips,
  className,
  groupError,
  radioGroupLabel,
  "aria-labelledby": ariaLabelledby,
  horizontal,
}: {
  config: Config;
  configField: TConfiguredField;
  form: UseForm;
  name: FieldPath<FormValues>;
  className?: string;
  horizontal?: boolean;
  groupError?: FieldError | undefined;
} & ConfiguredRadioGroupProps &
  ConfiguredRadioGroupLabelProps) {
  const required = requiredField(config, configField);
  const registerOptions = useAddRequiredToRegisterOptions<FormValues, typeof name>(
    undefined,
    required,
  );

  if (!showField(config, configField)) {
    return null;
  }

  const labelProps = radioGroupLabel
    ? { label: radioGroupLabel }
    : { "aria-labelledby": ariaLabelledby! };

  return (
    <RadioGroup
      {...labelProps}
      className={className}
      horizontal={horizontal}
      groupError={<FormattedFieldError inputName={name} error={groupError} />}
    >
      {radioButtons?.map(
        ({ label, value, disabledOverride, "data-automation-id": dataAutomationId }, i) => (
          <RadioLabel
            key={i}
            label={label}
            data-automation-id={dataAutomationId}
            radio={
              <RadioInput
                disabled={disabledOverride ?? readonlyField(config, configField)}
                value={value}
                aria-invalid={isAriaInvalid(groupError)}
                {...form.register(name, registerOptions)}
              />
            }
          />
        ),
      )}
      {choiceChips?.map(
        ({ label, value, disabledOverride, "data-automation-id": dataAutomationId }, i) => (
          <ChoiceChip
            key={i}
            label={label}
            data-automation-id={dataAutomationId}
            radio={
              <RadioInput
                disabled={disabledOverride ?? readonlyField(config, configField)}
                value={value}
                // TODO: [BIZ-5333] add required field
                {...form.register(name)}
              />
            }
          />
        ),
      )}
    </RadioGroup>
  );
}
export function ConfiguredCheckboxGroup({
  config,
  configField,
  form,
  name,
  customErrorMsg,
  groupError,
  checkboxes,
  checkboxGroupLabel,
  checkboxGroupLabelledBy,
  checkboxGroupSublabel,
  requiredOverride,
}: {
  config: Config;
  configField: TConfiguredField;
  form: UseForm;
  name: FieldPath<FormValues>;
  customErrorMsg?: string;
  groupError?: FieldError | undefined;
  checkboxes: Option[];
  checkboxGroupLabel?: PresentNode;
  checkboxGroupLabelledBy?: string;
  checkboxGroupSublabel?: PresentNode;
  // To be used sparingly, will override requiredField
  requiredOverride?: true;
}) {
  const required = requiredOverride ?? requiredField(config, configField);
  const registerOptions = useAddRequiredToRegisterOptions<FormValues, typeof name>(
    undefined,
    required,
    customErrorMsg,
  );

  if (!showField(config, configField)) {
    return null;
  }

  const labelProps = {} as LabelPresent | AriaLabelledByPresent;

  if (checkboxGroupLabel) {
    labelProps.label = checkboxGroupLabel;
  } else if (checkboxGroupLabelledBy) {
    labelProps["aria-labelledby"] = checkboxGroupLabelledBy;
  }
  return (
    <CheckboxGroup
      sublabel={checkboxGroupSublabel}
      {...labelProps}
      required={required}
      groupError={<FormattedFieldError inputName={name} error={groupError} />}
    >
      {checkboxes.map(
        ({ label, value, disabledOverride, "data-automation-id": dataAutomationId }) => (
          <CheckboxLabel
            key={value}
            label={label}
            checkbox={
              <Checkbox
                data-automation-id={dataAutomationId}
                aria-invalid={isAriaInvalid(groupError)}
                disabled={readonlyField(config, configField) || disabledOverride}
                value={value}
                {...form.register(name, registerOptions)}
              />
            }
          />
        ),
      )}
    </CheckboxGroup>
  );
}

export function ConfiguredCheckbox({
  config,
  configField,
  form,
  name,
  customErrorMsg,
  subLabel,
  label,
  value,
  disabledOverride,
  requiredOverride,
}: {
  config: Config;
  configField: TConfiguredField;
  form: UseForm;
  name: FieldPath<FormValues>;
  customErrorMsg?: string;
  disabledOverride?: true;
  requiredOverride?: true;
  label: PresentNode;
  subLabel?: PresentNode;
  value: string;
}) {
  const required = requiredOverride ?? requiredField(config, configField);
  const registerOptions = useAddRequiredToRegisterOptions<FormValues, typeof name>(
    undefined,
    required,
    customErrorMsg,
  );

  if (!showField(config, configField)) {
    return null;
  }

  return (
    <CheckboxLabel
      label={label}
      subLabel={subLabel}
      checkbox={
        <Checkbox
          aria-invalid="false"
          disabled={disabledOverride ?? readonlyField(config, configField)}
          value={value}
          {...form.register(name, registerOptions)}
        />
      }
    />
  );
}

export function scrollAndFlashError(
  element: HTMLElement,
  alignment: ScrollLogicalPosition | undefined = "center",
) {
  element.scrollIntoView({ block: alignment });
  element.focus();
  element.onanimationend = () => {
    element.classList.remove(Styles.errorFlash);
  };
  // this may need to be revisted or altered to handle all input components
  element.classList.add(Styles.errorFlash);
}

export function showField(config: Config, field: TConfiguredField) {
  return config[field].display !== "hidden";
}

export function readonlyField(config: Config, field: TConfiguredField) {
  return config[field].display === "readonly";
}

export function requiredField(config: Config, field: TConfiguredField) {
  return config[field].required === true;
}

export function downgradeDisplay<
  SectionConfig extends Partial<Config>,
  SectionConfiguredField extends Extract<keyof SectionConfig, TConfiguredField>,
>(config: SectionConfig, field: SectionConfiguredField, newDisplay: "readonly" | "hidden") {
  const fieldConfig = config[field]!;
  if (
    (newDisplay === "readonly" && fieldConfig.display === "editable") ||
    newDisplay === "hidden"
  ) {
    config[field] = {
      ...fieldConfig,
      display: newDisplay,
    };
  }
}

export function setEditableDisplay<
  SectionConfig extends Partial<Config>,
  SectionConfiguredField extends Extract<keyof SectionConfig, TConfiguredField>,
>(config: SectionConfig, field: SectionConfiguredField) {
  const fieldConfig = config[field]!;
  config[field] = {
    ...fieldConfig,
    display: "editable",
  };
}

export function useTrack() {
  const [activeOrganizationId] = useActiveOrganization();
  return (event: string, data?: Record<string, unknown>) =>
    segmentTrackAsync(`transaction creation: ${event}`, {
      ...data,
      org_id: activeOrganizationId,
    });
}
